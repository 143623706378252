import axios from 'axios'
import { cachey } from '@/utils/cms/cachey'

export const ENVIRONMENT_NAME = process.env.GITLAB_ENVIRONMENT_NAME || 'local'
export const RUNTIME_ENVIRONMENT_NAME = process.env.NEXT_PUBLIC_BUILD_ENVIRONMENT || 'local'

export const CMS_URLS = {
  local: 'https://develop.strapi.des.de',
  develop: 'https://develop.strapi.des.de',
  production: 'https://strapi.des.de',
}

export async function getCMSData(endpoint, options = {}) {
  // const cmsUrl = CMS_URLS[ENVIRONMENT_NAME]
  const cmsUrl = 'https://strapi.des.de'

  const startMs = Date.now()

  const log = (...args) => {
    // eslint-disable-next-line no-console
    if (!options.hideLog) console.log.call(null, ...args)
  }

  const call = async () => {
    log('cms out', endpoint)
    const response = await axios.get(`${cmsUrl}/${endpoint}`)
    log('cms in', endpoint, Date.now() - startMs, 'ms')
    return response.data
  }

  if (options.noCache) {
    return await call()
  } else {
    return await cachey.cachey(endpoint, async () => {
      return await call()
    })
  }
}

export async function getGlobalCMSData(context) {
  const locale = context.locale
  const [menu, footer, seo] = await Promise.all([
    getCMSData(`menu?_locale=${locale}`),
    getCMSData(`footer?_locale=${locale}`),
    getCMSData(`seo?_locale=${locale}`),
  ])
  return { menu, seo, footer }
}

export async function getPageData(context) {
  const path = context.params.id.join('/')
  const locale = context.locale
  const [menu, footer, seo, page] = await Promise.all([
    getCMSData(`menu?_locale=${locale}`),
    getCMSData(`footer?_locale=${locale}`),
    getCMSData(`seo?_locale=${locale}`),
    getCMSData(`all?path=${path}&_locale=${locale}`),
  ])
  return { menu, footer, seo, page }
}

const ASSET_VERSIONS = {
  local: 'latest',
  develop: 'latest',
  production: 'v22',
}

export async function getExperienceData(context) {
  const assetVersion = ASSET_VERSIONS[ENVIRONMENT_NAME]
  const noCache = ENVIRONMENT_NAME === 'develop'
  const locale = context.locale
  const experienceData = await getCMSData(`experience?version=${assetVersion}&resolution=${2048}&_locale=${locale}`, {
    noCache,
  })
  return experienceData
}
