//https://medium.com/trabe/synchronize-cache-updates-in-node-js-with-a-mutex-d5b395457138
const EventEmitter = require('events')
const lock = () => {
  const locked = {}
  const ee = new EventEmitter()
  ee.setMaxListeners(0)

  return {
    acquire: key =>
      new Promise(resolve => {
        if (!locked[key]) {
          locked[key] = true
          return resolve()
        }

        const tryAcquire = value => {
          if (!locked[key]) {
            locked[key] = true
            ee.removeListener(key, tryAcquire)
            return resolve(value)
          }
        }

        ee.on(key, tryAcquire)
      }),

    // If we pass a value, on release this value
    // will be propagated to all the code that's waiting for
    // the lock to release
    release: (key, value) => {
      Reflect.deleteProperty(locked, key)
      setImmediate(() => ee.emit(key, value))
    },
  }
}

const fetchLock = lock()
const map = new Map()
/**
 * Simple key based cache with little extra that ensures only one async function
 * per key is executed each time a cache entry needs to be filled.
 *
 * @see https://medium.com/trabe/synchronize-cache-updates-in-node-js-with-a-mutex-d5b395457138
 */
export const cachey = {
  /**
   * clear simply clears the cache Store
   * @returns {number} size of cache before clear
   */
  clear: () => {
    const size = map.size
    map.clear()
    return size
  },
  /**
   * Simple key based cache with little extra that ensures only one async function
   * per key is executed each time a cache entry needs to be filled.
   *
   * @see https://medium.com/trabe/synchronize-cache-updates-in-node-js-with-a-mutex-d5b395457138
   * @param key Cache key
   * @param fn Async function to call if cache entry for key needs to be filled
   * @returns {Promise<any>} Blocks if cache entry for key is currently filled
   */
  cachey: async (key, fn) => {
    let value = map.get(key)

    if (!value) {
      value = await fetchLock.acquire(key)
      try {
        if (!value) {
          value = await fn()
          map.set(key, value)
        }
      } finally {
        fetchLock.release(key, value)
      }
    }
    //return immutable
    return JSON.parse(JSON.stringify(value))
  },
}
